import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import _get from 'lodash/get'
import { AppContext } from '../AppProvider'

import Header from './Header'
import Footer from './Footer'
import MobileMenu from './Menu/MobileMenu'
import CookieBar from './CookieBar'
import Modal from './Modal'

import media from '../styles/media'
import theme from '../styles/theme'
import vars from '../styles/vars'

import utils from '../utils'

const layoutQuery = graphql`
	query LayoutQuery {
		prismic {
			allLayouts {
				edges {
					node {
						...LayoutFragment
					}
				}
			}
		}
	}
`

const MainWrapper = styled.div`
	height: 100vh; /* important */
	position: relative;
	right: 0;
	transition-duration: 0.3s;
	${({ isMobileMenuOpen }) =>
		isMobileMenuOpen &&
		css`
			width: 80%;
			height: 100vh;
			position: fixed;
			top: 0;
			right: 80%;
			overflow-y: hidden;	
	`};

		${media.greaterThan('tablet')`
			${({ isMobileMenuOpen }) =>
			isMobileMenuOpen &&
			css`
					right: ${vars.mobileSideMenuWidth.lg}rem;
			`};
	`};

	${({ isModalOpen }) =>
		isModalOpen &&
		css`
			overflow-y: hidden;	
	`};
`

const HeaderStyle = styled.div`
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100;
`

const PageStyle = styled.div`
	position: relative;
`

const Layout = (props) => {
	const { transparentHeader, children, pathName } = props
	const { mobileMenu, modal, closeModal } = React.useContext(AppContext)

	return (
		<StaticQuery
			query={`${layoutQuery}`}
			render={withPreview(({ prismic }) => {
				const doc = prismic.allLayouts.edges.slice(0, 1).pop()
				const {
					main_menu,
					message,
					button_one_title,
					button_one_link,
					button_one_color,
					button_two_title,
					button_two_link,
					button_two_color
				} = _get(doc, 'node')
				return (
					<AppContext.Consumer>
						{(context) => (
							<ThemeProvider theme={theme}>
								<MainWrapper
									isMobileMenuOpen={mobileMenu.open}
									isModalOpen={modal.open}>
									<CookieBar message={message} pathName={pathName} />
									<PageStyle>
									<HeaderStyle>
									<Header
										context={context}
										items={main_menu}
										buttonOne={{
											title: button_one_title,
											link: utils.linkResolver(button_one_link),
											color: button_one_color
										}}
										buttonTwo={{
											title: button_two_title,
											link: utils.linkResolver(button_two_link),
											color: button_two_color
										}}
										transparentHeader={transparentHeader}
									/>
									</HeaderStyle>
									<main>{children}</main>
									<Footer />
									</PageStyle>
								</MainWrapper>
								<MobileMenu
									buttonOne={{
										title: button_one_title,
										link: utils.linkResolver(button_one_link),
										color: button_one_color
									}}
									buttonTwo={{
										title: button_two_title,
										link: utils.linkResolver(button_two_link),
										color: button_two_color
									}}
									items={main_menu} />
								<Modal closeModal={closeModal} {...modal} />
							</ThemeProvider>
						)}
					</AppContext.Consumer>
				)
			}, layoutQuery)}
		/>
	)
}

Layout.propTypes = {
	transparentHeader: PropTypes.bool,
	children: PropTypes.node.isRequired
}

export default Layout

import React from 'react'
import styled from 'styled-components'

import Cross from './Cross'
import media from '../../styles/media'
import rgba from '../../utils/rgba'

const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 9;
  display: ${({ show }) => show ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => rgba(theme.colors.dark, 0.8)};
  overflow-y: scroll;
`

const Wrapper = styled.div`
  width: auto;
  height: auto;
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: ${({ theme }) => theme.colors.white};

  ${media.greaterThan("tablet")`
    
  `}
`
const ModalMain = styled.div`
  padding: 0;
  ${media.lessThan("tablet")`
    iframe { 
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
  `}
`

const IframeModal = ({
  main,
  open,
  closeModal
}) => (
    <Container
      show={open}
      onClick={() => closeModal()}>
      <Cross onClick={() => closeModal()} />
      <Wrapper>
        <ModalMain>{main}</ModalMain>
      </Wrapper>
    </Container>
  )

export default IframeModal
import React from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import styled from 'styled-components'

import vars from '../styles/vars'
import media from '../styles/media'
import theme from '../styles/theme'

import RedAntLogoImage from '../images/ra-logo.svg'

import Menu from './Menu'
import BurgerMenuToggle from './Menu/BurgerMenuToggle'
import Button from '../components/Button'

const HeaderContainerOuter = styled.div`
	background: ${({ isTransparent, theme }) => isTransparent ? 'transparent' : theme.colors.primary};
`

const HeaderContainer = styled.header`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: calc(100% - ${vars.gap.lg}rem);
	height: ${vars.headerHeight.lg}rem;
	margin: 0 auto;
	padding: 0;

	${media.greaterThan('tablet')`
    max-width: 1391px; /* important for mobile menu */
  `};
`

const LeftContentWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
`

const RightContentWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: relative; /* important for sub-menu to remain in place */

	${media.lessThan('desktop')`
		justify-content: flex-end;
		align-items: flex-end;
	`}
`

const LogoLink = styled(Link)`
  z-index: 2;
`

const Logo = styled.img`
	width: 3.875rem;
	height: 3.875rem;
`

const HeaderButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	> button {
		margin-left: 0.625rem;
	}

	${media.lessThan('desktop')`
    display: none; /* hide desktop menu */
  `};
`

const handleOnClick = (formUrl) => {
	if (typeof window !== `undefined`) {
		const scrollConfig = { behavior: 'smooth' }
		const elmntToScrollTo = window.document.querySelectorAll("section[type='contact_form']")[0]
		if (elmntToScrollTo) {
			elmntToScrollTo.scrollIntoView(scrollConfig)
		} else {
			navigate(formUrl)
		}
	}
}

const getButtonVariant = (color) => ({
	'dark': 'roundCornersDark',
	'light': 'roundCornersLight',
	'red': 'roundCornersRed'
})[color] || 'dark'

const Header = ({ items, transparentHeader, buttonOne, buttonTwo }) => {
	return (
		<HeaderContainerOuter isTransparent={transparentHeader}>
			<HeaderContainer>
				<LeftContentWrapper>
					<LogoLink to={`/`}>
						<Logo src={RedAntLogoImage} alt="Red Ant Logo" />
					</LogoLink>
				</LeftContentWrapper>
				<RightContentWrapper>
					{items.length && <Menu items={items} istransparent={transparentHeader} />}
					<BurgerMenuToggle isTransparent={transparentHeader} />
					<HeaderButtonsWrapper>
						{buttonOne.title && (
							<Button href={buttonOne.link} variant={getButtonVariant(buttonOne.color)}>
								{buttonOne.title}
							</Button>
						)}
						{buttonTwo.title && (
							<Button onClick={() => handleOnClick(buttonTwo.link)} variant={getButtonVariant(buttonTwo.color)}>
								{buttonTwo.title}
							</Button>
						)}
					</HeaderButtonsWrapper>
				</RightContentWrapper>
			</HeaderContainer>
		</HeaderContainerOuter>
	)
}

export default Header

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"
import _compact from "lodash/compact"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic-graphql"

function SEO({ title, description, keywords, image, url, meta, lang, noIndex }) {
  const seoQuery = graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `

  return (
    <StaticQuery
      query={`${seoQuery}`}
      render={withPreview(({ site }) => {
        const metaTitle = title || site.siteMetadata.description
        const metaDescription = description || site.siteMetadata.description
        const metaKeywords = keywords || site.siteMetadata.keywords
        const metaImage = _get(image, 'url')
        const metaUrl = url
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={metaTitle}
            titleTemplate={`${metaTitle}`}
            meta={_compact([
              noIndex && {
                name: `robots`,
                content: `noindex`,
              },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `keywords`,
                content: metaKeywords,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              metaUrl && {
                property: `og:url`,
                content: metaUrl,
              },
              metaImage && {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              metaImage && {
                name: `twitter:image`,
                content: metaImage,
              },
            ]).concat(meta)}
          />
        )
      }, seoQuery)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO

import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { RichText } from 'prismic-reactjs'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'
import _chunk from 'lodash/chunk'

import H4 from './../H4'
import BottomMenu from './BottomMenu'
import QuickAccessMenu from './QuickAccessMenu'

import media from '../../styles/media'

import utils from '../../utils'

const Nav = styled.nav`
  display: flex;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  
  ${({ isTransparent, shouldHover, theme }) =>
    (!isTransparent && shouldHover) &&
    css`
      &:hover > a {
        color: ${theme.colors.black};
        background: ${theme.colors.white};
      }
    `
  }

  ${({ isTransparent, shouldHover, theme }) =>
    (isTransparent && shouldHover) && css`
      > a {
        color: ${theme.colors.black};
        background: ${theme.colors.white};
      }
    `
  }

  &:hover > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
`

const NavLink = styled(Link)`
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  ${({ isTransparent, theme }) =>
    isTransparent
      ? css`
      color: ${theme.colors.dark};
    `
      : css`
      color: ${theme.colors.white};
      &:hover {
        color: ${({ shouldHover, theme }) => shouldHover ? theme.colors.black : theme.colors.white};
        background: ${({ shouldHover, theme }) => shouldHover ? theme.colors.white : 'transparent'};
      }
    `};
  
  padding: 1.1rem 2.7rem 1.5rem 2.7rem;
  margin: 0.625rem 0 0.625rem 0;
  text-decoration: none;
  border-top-left-radius: .625rem;
  border-top-right-radius: .625rem;

  ${media.greaterThan("desktop")``}
`

const SectionTitle = styled(H4)`
  padding-bottom: 1.5rem;
`

const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  top: 5rem;
  left: 0.625rem;
  width: 1154px;
  height: auto;
  color: ${props => props.theme.colors.black};
  background: ${props => props.theme.colors.white};
  border-top-left-radius: .625rem;
  border-top-right-radius: .625rem;
  border-bottom-left-radius: .625rem;
  border-bottom-right-radius: .625rem;
`

const TopContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 2rem 2rem 1rem 2rem;
`

const BottomContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 11.25rem;
  background: #F4F4F8;
  border-bottom-left-radius: .625rem;
  border-bottom-right-radius: .625rem;
`

const TopicContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: .35;
  > * { max-width: 260px; }
  > a {
    ${({ theme }) => css`
      &:hover {
        > * {   
          color: ${utils.rgba(theme.colors.black, 0.8)};
        }
      }
    `};
  }
`

const LinksListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: .65;
`

const LinksListColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  > ul + ul {
    margin-left: 2rem;
  }
`

const LinksListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  max-width: 304px;
  padding: 0;
  margin: 0;
  list-style-type: none;
`

const BottomLeftWrapper = styled.div``
const BottomRightWrapper = styled.div``

const Overlay = styled.div`
  z-index: -1;
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.3);
  ${props => props.show && css`
    width: 100%;
    height: 100%;
  `}
`


const SubItem = styled.li`
  display: flex;
  flex: 1;
  padding-bottom: 1rem;
  margin-right: 5%;

  &:hover {
    opacity: 0.8;
  }
`
const SubTitle = styled.div`
  font-family: RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.14;
  color: ${props => props.theme.colors.black};
  padding-bottom: 0.35rem;
`

const SubDescription = styled.div`
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.75rem;
  line-height: 1.58;
  color: #707070;
`
const SubMenuItem = ({
  main_nav_link_name,
  main_nav_link_description,
  main_nav_link
}) => {
  return (
    <SubItem>
      <Link to={utils.linkResolver(main_nav_link)}>
        <SubTitle>{main_nav_link_name}</SubTitle>
        <SubDescription>{main_nav_link_description}</SubDescription>
      </Link>
    </SubItem>
  )
}

const MenuDropdown = ({
  item,
  istransparent,
}) => {
  const {
    name,
    has_dropdown_menu,
    title,
    description,
    link,
    main_navigation_title,
    main_navigation,
    bottom_navigation_title,
    bottom_navigation,
    quick_access_title,
    quick_access_navigation
  } = _get(item, 'menu_item', {})

  const [isActive, setIsActive] = React.useState(false)

  const hasBottomNav = _size(bottom_navigation) > 0
  const hasQuickAccessNav = _size(quick_access_navigation) > 0
  const [mainNavColumn1, mainNavColumn2] = [main_navigation.slice(0,2), main_navigation.slice(2,main_navigation.length)]

  return (
    <>
      <Nav
        shouldHover={has_dropdown_menu}
        isTransparent={istransparent}
        onMouseEnter={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(false)}
      >
        <NavLink
          to={utils.linkResolver(link)}
          isTransparent={istransparent}>{name}</NavLink>
        {has_dropdown_menu && (
          <DropdownMenu>
            <TopContainer>
              <TopicContainer>
                <Link to={utils.linkResolver(link)}>
                  <SectionTitle variant="primary">{title}</SectionTitle>
                  <RichText render={description} htmlSerializer={utils.htmlSerializer} />
                </Link>
              </TopicContainer>
              <LinksListContainer>
                <SectionTitle variant="primary">{main_navigation_title}</SectionTitle>
                <LinksListColumns>
                  {mainNavColumn1 && (
                    <LinksListWrapper>
                      {_map(mainNavColumn1, (item, index) => <SubMenuItem key={index} {...item} />)}
                    </LinksListWrapper>
                  )}
                  {mainNavColumn2 && (
                    <LinksListWrapper>
                      {_map(mainNavColumn2, (item, index) => <SubMenuItem key={index} {...item} />)}
                    </LinksListWrapper>
                  )}
                </LinksListColumns>
              </LinksListContainer>
            </TopContainer>
            {(hasBottomNav || hasQuickAccessNav) && (
              <BottomContainer>
                <BottomLeftWrapper>
                  {hasBottomNav && (<BottomMenu title={bottom_navigation_title} items={bottom_navigation} />)}
                </BottomLeftWrapper>
                <BottomRightWrapper>
                  {hasQuickAccessNav && (<QuickAccessMenu title={quick_access_title} items={quick_access_navigation} />)}
                </BottomRightWrapper>
              </BottomContainer>
            )}
          </DropdownMenu>
        )}
      </Nav>
      <Overlay show={isActive} />
    </>
  )
}

export default MenuDropdown
import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'

import MenuDropdown from './MenuDropdown'

import media from '../../styles/media'
import utils from '../../utils'

const MenuList = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0.937rem 0.625rem;
  
  ${media.lessThan("desktop")`
    display: none; /* hide desktop menu */
  `}
`

const Item = styled.li`
  z-index: 9;
  display: flex;
  color: ${props => props.theme.colors.dark};
`

const StyledLink = styled(Link)`
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  ${(props) =>
    props.istransparent
      ? css`
      color: ${props.theme.colors.dark};
      &:hover {
        color: ${(props) => utils.rgba(props.theme.colors.dark, 0.8)};
      }
    `
      : css`
      color: ${props.theme.colors.white};
      &:hover {
        color: ${(props) => props.shouldHover ? props.theme.colors.black : utils.rgba(props.theme.colors.white, 0.8)};
        background: ${(props) => props.shouldHover ? props.theme.colors.white : 'transparent'};
      }
    `};
  
  padding: 1.1rem 2.7rem 1.5rem 2.7rem;
  margin: 0.625rem 0 0.625rem 0;
  text-decoration: none;
  border-top-left-radius: .625rem;
  border-top-right-radius: .625rem;

  ${media.greaterThan("desktop")``}
`

const Menu = ({
  items,
  ...rest
}) => {
  return (
    <MenuList>
      {_map(items, (item, key) => {
        const { name, link, has_dropdown_menu } = _get(item, 'menu_item') || {}
        return (
          <Item key={key}>
            {has_dropdown_menu
              ? <MenuDropdown
                item={item}
                {...rest} />
              : <StyledLink
                to={utils.linkResolver(link)}
                {...rest}>
                {name}
              </StyledLink>
            }
          </Item>
        )
      })}
    </MenuList>
  )
}

export default Menu
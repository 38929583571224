import React, { useCallback } from 'react'
import styled from 'styled-components'

import Button from '../Button'
import H2 from '../H2'

import Cross from './Cross'
import media from '../../styles/media'
import rgba from '../../utils/rgba'

const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 9;
  display: ${({ show }) => show ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => rgba(theme.colors.dark, 0.8)};
  overflow-y: scroll;
`

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: none;
  margin-bottom: 5rem;
  background-color: ${({ theme }) => theme.colors.white};

  ${media.greaterThan("tablet")`
    top: 10rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 80%;
  `}
  ${media.greaterThan("desktop")`
    top: 20rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 880px;
  `}
`

const ModalHeader = styled.div`
  height: 8.312rem;
  text-align: center;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.colors.secondary};
`
const ModalMain = styled.div`
  padding: 0 3.43rem;
`
const ModalFooter = styled.div`
  padding: 3.43rem 3.43rem 1.43rem 3.43rem;
  background: ${({ theme }) => theme.colors.light};
  text-align: center;
`

const ModalTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  h2 {
    margin-bottom: 0;
  }
`

const DefaultModal = ({
  header,
  main,
  footer,
  open,
  closeModal
}) => (
    <Container
      show={open}
      onClick={() => closeModal()}>
      <Wrapper>
        <ModalHeader>
          <Cross onClick={() => closeModal()} />
          <ModalTitle>
            <H2 variant="subHeading" color="light">{header}</H2>
          </ModalTitle>
        </ModalHeader>
        <ModalMain>{main}</ModalMain>
        {footer && (
          <ModalFooter>{footer}</ModalFooter>
        )}
      </Wrapper>
    </Container>
  )

export default DefaultModal
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import _get from "lodash/get"
import _map from "lodash/map"
import media from "../../styles/media"
import utils from '../../utils'

import Image from './../image'

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  
  width: 100%;
  padding: .5rem;

  > div {
    display: flex;
    flex: 1;
    align-items: center;
    width: 100%;
  }
  > div:nth-child(1) { justify-content: center; }
  > div:nth-child(2) { justify-content: center; }

  ${media.greaterThan("tablet")`
    width: 100%;
    flex-direction: row;
    > div:nth-child(1) { justify-content: flex-start; }
    > div:nth-child(2) { justify-content: flex-end; }
  `}

  
`

const DisclaimerStyle = styled.p`
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.75rem;
  color: ${props => props.theme.colors.lightSecondary};
  padding: 0.3125rem;
  margin: 0;
  a {
    color: ${props => props.theme.colors.lightSecondary};
  }
  sup { vertical-align: baseline; }
`
const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  p {
    line-height: 1;
  }
`
const SocialMediaIcons = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0;
  > li + li {
    margin-left: 0.937rem;
  }
`

const SocialMediaIcon = styled.li`
  > img {
    width: 2.187rem;
    height: 2.187rem;
  }

  &:hover {
    opacity: 0.7;
  }

`
const EnvironmentContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 0.75rem;
  color: ${props => props.theme.colors.dark};
`

const BottomBar = ({ privacyPolicy, legal, socialMediaIconsList }) => {
  return (
    <BottomContainer>
      <div>
        <DisclaimerContainer>
          <DisclaimerStyle> <sup>©</sup> Red Ant <sup>®</sup>.</DisclaimerStyle>
          <DisclaimerStyle>All rights reserved</DisclaimerStyle>
        </DisclaimerContainer>
        <DisclaimerContainer>
          <DisclaimerStyle>
            <Link to={utils.linkResolver(privacyPolicy)}>{_get(privacyPolicy, "page_title")}</Link>
          </DisclaimerStyle>
          <DisclaimerStyle>
            <Link to={utils.linkResolver(legal)}>{_get(legal, "page_title")}</Link>
          </DisclaimerStyle>
        </DisclaimerContainer>
      </div>
      <div>
      {socialMediaIconsList && (
        <SocialMediaIcons>
          {_map(socialMediaIconsList, (item, index) => {
            const { link, icon } = item || {}
            return (
              <SocialMediaIcon key={index}>
                <a href={utils.linkResolver(link)} target="_blank">
                  <Image {...icon} />
                </a>
              </SocialMediaIcon>
            )  
          })}
        </SocialMediaIcons>
      )}
      

      </div>
    </BottomContainer>
  )
}

BottomBar.propTypes = {}

BottomBar.defaultProps = {}

export default BottomBar

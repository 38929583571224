import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import _map from 'lodash/map'

import H4 from './../H4'
import Image from './../image'

import utils from '../../utils'


const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 2.18rem 1.5rem 2.18rem;
`

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  min-width: 7.85rem;
  text-align: center;
  
  > img {
    width: 5.125rem;
    height: 5.125rem;
  }

  &:hover {
    opacity: 0.7;
  }
`
const Label = styled.div`
  text-align: center;
  font-family: RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.937rem;
  color: ${props => props.theme.colors.black};
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 3rem;
`
const BottomMenu = ({ title, items }) => {
  return (
    <Container>
      <TitleContainer>
      <H4 variant="primary">{title}</H4>
      </TitleContainer>
      <ItemsWrapper>
        {_map(items, item => {
          const { bottom_nav_link_name, bottom_nav_link_icon, bottom_nav_link } = item || {}
          return (
            <Item key={bottom_nav_link_name} to={utils.linkResolver(bottom_nav_link)}>
              <Image {...bottom_nav_link_icon} />
              <Label>{bottom_nav_link_name}</Label>
            </Item>
          )
        })}
      </ItemsWrapper>
    </Container>
  )
}

export default BottomMenu
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'
import { AppContext } from '../../../AppProvider'
import Image from '../../Image'
import Cross from '../Cross'
import media from '../../../styles/media'
import rgba from '../../../utils/rgba'
import texture from "./assets/texture.png"
import sofa from "./assets/sofa.png"
import play_button from "./assets/play_button.png"

const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 9;
  display: ${({ show }) => show ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => rgba(theme.colors.dark, 0.8)};
  overflow-y: scroll;
`
const Wrapper = styled.div`
  z-index: 10;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: none;
  margin-bottom: 5rem;

  ${media.greaterThan("tablet")`
    top: 3rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 80%;
  `}
  ${media.greaterThan("desktop")`
    top: 3rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 800px;
  `}
`

const ModalMain = styled.div`
  position: relative;
  max-width: 800px;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.125rem;
  background-color: #34B8C7;
  background: url(${texture}), linear-gradient(180deg, #34B8C7 0%, #ECFDFF 100%);
  background-blend-mode: screen;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 26px;
  box-shadow: 2px -1px 3px 0 rgba(255,255,255,0.37);
  align-items: center;
  text-align: center;

  ${media.greaterThan("tablet")`
    min-height: 604px;
  `}
`
const TextContainer = styled.div`
  z-index: 2;
  text-align: center;
  > h2 {    
    max-width: 550px;
    font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 48px;
    line-height: 2.5rem;
    color: #ffffff;
    margin-bottom: 30px;
    text-shadow: 0 2px 4px rgba(0,0,0,0.31);
  }

  > p {
    max-width: 400px;
    color: #FFFFFF;
    font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-family: Helvetica;
    font-size: 17px;
    line-height: 1.7;
    text-shadow: 0 2px 4px rgba(0,0,0,0.20);
    letter-spacing: 0;
    margin: 0 auto 95px auto;
  }
`

const SofaImage = styled.img`
  z-index: 0;
  width: 900px;
  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translate(-50%, 0);
`
const VideoContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  bottom: 80px;
  left: unset;
  transform: none;
  z-index: 1;
  > iframe {
    z-index: 2;
    top:0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  > img {
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  ${media.greaterThan("tablet")`
    width: 547px;
    height: 306px;
  `}
`
const PlayButton = styled.button`
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) }
  background-color: transparent;
  outline: none;
  border: none;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  width: 123px;
  > img {
    width: 100%;
  }
  ${media.greaterThan('tablet')`
    &:hover {
      > img {
        filter: drop-shadow(4px 12px 10px rgba(0,0,0,0.5));
      }
    }
  `}
`

const PromoModalFurnitureVillage = ({
  main,
  open,
  closeModal,
  modalProps
}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies([ 'promo_home_page' ])
  const { openModal } = React.useContext(AppContext)
  
  const closePromoModal = useCallback((e) => {
    e.stopPropagation()
    closeModal()
    setCookie(`promo_home_page`, true, { path: '/' })
  }, [closeModal, setCookie])

  const handlePlayVideo = useCallback((e) => {
    e.stopPropagation()
    setIsPlaying(true)
  })

  const {
    popup_furniturevillage_vimeo_video_id: vimeo_video_id,
    popup_furniturevillage_video_thumbnail: video_thumbnail,
    popup_furniturevillage_title: title,
    popup_furniturevillage_subtitle: subtitle
  } = modalProps
  return (
    <Container
      show={open}
      onClick={closePromoModal}>
      <Wrapper
        onClick={(e) => { e.stopPropagation() }}>
        <ModalMain>
          <Cross color={'#ffffff'} onClick={closePromoModal} />
          <TextContainer>
            <h2>{title}</h2>
            <p>{subtitle}</p>
          </TextContainer>
          <VideoContainer>
            {((vimeo_video_id && isPlaying) || !video_thumbnail) && <iframe src={`https://player.vimeo.com/video/${vimeo_video_id}${video_thumbnail ? '&autoplay=1' : ''}`} width="604" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>}
            {video_thumbnail && <Image {...video_thumbnail} alt={''}/>}
            {(!isPlaying && video_thumbnail) && <PlayButton onClick={handlePlayVideo}>
              {play_button && <img src={play_button} />}
            </PlayButton>}
          </VideoContainer>
          {sofa && <SofaImage src={sofa} />}
        </ModalMain>
      </Wrapper>
    </Container>
  )
}

export default PromoModalFurnitureVillage
import React from 'react'
import DefaultModal from './DefaultModal'
import PromoModal from './PromoModal'
import PromoModalFlannels from './PromoModalFlannels'
import PromoModalFurnitureVillage from './PromoModalFurnitureVillage/PromoModalFurnitureVillage'
import IframeModal from './IframeModal'

const ModalComponent = ({ type, ...rest }) => ({
  'default': <DefaultModal {...rest} />,
  'promo': <PromoModal {...rest} />,
  'promoFlannels': <PromoModalFlannels {...rest} />,
  'promoFurnitureVillage': <PromoModalFurnitureVillage {...rest} />,
  'iframe': <IframeModal {...rest} />
}[type] || <DefaultModal {...rest} />)

const Modal = ({
  type = 'default',
  ...rest
}) => {
  return <ModalComponent
    type={type}
    {...rest}
  />
}

export default Modal
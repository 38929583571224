import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { useCookies } from 'react-cookie'

import Container from './Container'
import Button from './Button'

import media from './../styles/media'

import utils from '../utils'

const Wrapper = styled.div`
  width: 100%;
	padding: 2.31rem 0;
  background-color: ${props => props.theme.colors.white};
  display: ${props => props.show ? 'block' : 'none'};
  border-bottom: 2px solid ${(props) => props.theme.colors.light};
`

const GreetingsContainer = styled.div`
  font-size: 1.56rem;
  line-height: 1.2;
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  padding-bottom: 0.75rem;
`

const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	${media.lessThan('tablet')`
    flex-direction: column;
    > a {
      justify-content: center;
    }
  `};
`

const CookieBar = ({ message, pathName }) => {
	const [ cookies, setCookie, removeCookie ] = useCookies([
		'cookies_preferences_set',
		'cookies_policy',
		'__hs_do_not_track',
		'__lf_do_not_track',
		`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`
	])
	const [ show, setShow ] = React.useState(false)
	const [ greeting, setGreeting ] = React.useState('')

	React.useEffect(
		() => {
			const handleCookiePreference = () => {
				const cookiePreference = _get(cookies, 'cookies_preferences_set')
				if (pathName === '/privacy') {
					setShow(false)
				} else {
					setShow(!cookiePreference)
				}
			}

			const handleGreeting = () => {
				const time = new Date().getHours()
				if (time < 12) {
					setGreeting(`Good morning!`)
				} else if (time < 20) {
					setGreeting(`Good afternoon!`)
				} else {
					setGreeting(`Good evening!`)
				}
			}

			handleCookiePreference()
			handleGreeting()

			// initialy opt-out of google analytics if cookies are not approved by the user yet.
			if (
				!_get(cookies, 'cookies_policy.analytics') &&
				!_get(cookies, `gdpr-google-analytics`)
			) {
				setCookie(`gdpr-google-analytics`, false)
			}
			// initialy opt-out of google tag manager if cookies are not approved by the user yet.
			if (
				!_get(cookies, 'cookies_policy.analytics') &&
				!_get(cookies, 'gdpr-google-tagmanager')
			) {
				setCookie(`gdpr-google-tagmanager`, false)
			}
			// initialy opt-out of hubspot tracking if cookies are not approved by the user yet.
			if (
				!_get(cookies, 'cookies_preferences_set') &&
				!_get(cookies, '__hs_do_not_track')) {
				setCookie(`__hs_do_not_track`, true, { path: '/' })
			}
			// initialy opt-out of leadforensics tracking if cookies are not approved by the user yet.
			if (
				!_get(cookies, 'cookies_preferences_set') &&
				!_get(cookies, '__lf_do_not_track')) {
				setCookie(`__lf_do_not_track`, true, { path: '/' })
			}
		},
		[ cookies, greeting, pathName ]
	)

	const acceptAllCookies = () => {
		setCookie('gdpr-google-analytics', true )
		setCookie('gdpr-google-tagmanager', true )
		setCookie('cookies_preferences_set', true, { path: '/' })
		setCookie('cookies_policy', { essential: true, analytics: true, hs: true }, { path: '/' })
		removeCookie(`__hs_do_not_track`, { path: '/' })
		removeCookie(`__lf_do_not_track`, { path: '/' })
		removeCookie(`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`, { path: '/' })
	}

	return (
		<Wrapper show={show}>
			<Container noPadding>
				<GreetingsContainer>{greeting}</GreetingsContainer>
				<RichText render={message} htmlSerializer={utils.htmlSerializer} />
				<ButtonsWrapper>
					<Button variant="primary" onClick={acceptAllCookies}>
						Accept all cookies
					</Button>
					<Button variant="optionsButton" href={`/privacy`}>
						More Options
					</Button>
				</ButtonsWrapper>
			</Container>
		</Wrapper>
	)
}

export default CookieBar

import React from 'react'
import styled from 'styled-components'

const CrossButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    &:hover {
      opacity: 0.8;
    }

    div {
      width: 28px;
      height: 4.6px;
      background: ${({ color }) => color};
      border-radius: 2.8px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 5.2px;

      :first-child {
        transform: rotate(45deg);
      }

      :nth-child(2) {
        transform: rotate(-45deg);
      }
    }
  `

const Cross = ({ color = "#ffffff", onClick }) => (
  <CrossButton color={color} onClick={onClick}>
    <div />
    <div />
  </CrossButton>
)

export default Cross
import React from 'react'
import styled, { css } from 'styled-components'
import { AppContext } from '../../AppProvider'

import media from '../../styles/media'
import rgba from '../../utils/rgba'

const StyledBurger = styled.div`
  position: relative;
  width: 22px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 9;

  ${media.greaterThan("desktop")` display: none;`}

  &:hover {
    opacity: 0.8;
  }

  div {
    z-index: 9;
    width: 22px;
    height: 3px;
    background: ${({ isTransparent, isActive, theme }) => (isTransparent && !isActive) ? theme.colors.dark : theme.colors.white};
    border-radius: 0.6rem;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1.3px;

    :first-child {
      transform: ${({ isActive }) => isActive ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ isActive }) => isActive ? '0' : '1'};
      transform: ${({ isActive }) => isActive ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ isActive }) => isActive ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

const MobileOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => rgba(theme.colors.dark, 0.75)};
  z-index: 8;
  cursor: pointer;
  ${({ isActive }) => !isActive
    && css`
        display: none;
      `}
`

const BurgerMenuToggle = ({ isTransparent }) => {
  const { mobileMenu, toggleMenu } = React.useContext(AppContext)
  return (
    <>
      <MobileOverlay
        isActive={mobileMenu.open}
        onClick={toggleMenu} />
      <StyledBurger
        isActive={mobileMenu.open}
        isTransparent={isTransparent}
        onClick={toggleMenu}>
        <div />
        <div />
        <div />
      </StyledBurger>
    </>
  )
}
export default BurgerMenuToggle
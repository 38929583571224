import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql, Link } from "gatsby"
import { withPreview } from "gatsby-source-prismic-graphql"

import _get from "lodash/get"
import _map from "lodash/map"
import media from "../../styles/media"
import utils from '../../utils'

import H3 from "../H3"
import P from "../P"
import FooterForm from "../Forms/FooterForm"

import BottomBar from "./BottomBar"

const FooterWrapper = styled.footer`
  background: ${props => props.theme.colors.light};
  position: relative;
  display: flex;
  flex-direction: column;
  ${media.lessThan("mobile")`
    flex-direction: column;
  `}
`
const Content = styled.div`
  padding-top: 3.437rem;
 ${media.greaterThan('mobile')`
    margin: auto; 
 `}
  ${media.greaterThan("desktop")`
    max-width: ${props => (props.fullWidth ? "initial" : "1200px")};
  `}
`
const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  ${media.greaterThan("mobile")`
    flex-direction: row;
    max-width: 925px;
    padding: 0;
  `}
  ${media.greaterThan("desktop")`
    max-width: 1200px;
    justify-content: center;
  `}
`
const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  ${media.greaterThan("mobile")`
    flex-direction: row;
    width: 22%;
    text-align: left;
  `}
`
const MiddleContainer = styled.div`
  flex-direction: column;
  width: 50%;
  text-align: left;
  ${media.greaterThan("mobile")`
    width: 20%;
    text-align: left;
  `}
`
const SignUpContainer = styled.div`
  flex-direction: column;
  width: 100%;
  text-align: center;
  ${media.greaterThan("mobile")`
  width: 25%;
  text-align: left;
  label {
    text-align: left;
  }
`}
`
const RightContainer = styled.div`
  flex-direction: column;
  width: 100%;
  text-align: left;
  h3 {
    text-align: center;
  }

  ul {
    column-count: 2;
  }
  ${media.greaterThan("mobile")`
    width: 26%;
    text-align: left;
    h3 {
      text-align: left;
    }
  
    ul {
      column-count: 1;
    }
  `}
`
const LeftContainerInnerLeft = styled.div`
  display: flex;
  flex-direction: column;
`
const RightContainerInnerLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.greaterThan("mobile")`
    align-items: flex-start;
  `}
`

const FooterList = ({ className, children }) => {
  return (
    <nav>
      <ul className={className}>{children}</ul>
    </nav>
  )
}
const LimitListStyle = styled(FooterList)`
  -moz-column-count: 1;
  -moz-column-gap: 0.625rem;
  -webkit-column-count: 1;
  -webkit-column-gap: 0.625rem;
  column-count: 1;
  column-gap: 0.625rem;
`
const StyledLink = styled.li`
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 2.15;
  color: ${props => props.theme.colors.darkSecondary};
  a {
    color: ${props => props.theme.colors.darkSecondary};

    &:hover, &:active, &:focus {
      color: ${props => utils.rgba(props.theme.colors.darkSecondary, 0.8)};
    }
  }
`
const StyledLinkTitle = styled.li`
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.0625rem;
  color: ${props => props.theme.colors.darkSecondary};
  a {
    color: ${props => props.theme.colors.darkSecondary};

    &:hover, &:active, &:focus {
      color: ${props => utils.rgba(props.theme.colors.darkSecondary, 0.8)};
    }
  }
`

const Break = styled.div`
  margin-bottom: 1rem;
`

const Footer = () => {
  const footerQuery = graphql`
    query {
      prismic {
        allMain_footers {
          edges {
            node {
              column_left {
                footer_item
                footer_item_link {
                  ...LinkSolutionFragment
                  ...LinkLegalFragment
                  ...LinkResourceFragment
                  ...LinkContactUsFragment
                  ...LinkPlatformFragment
                  ...LinkCompanyFragment
                  ...LinkArticleFragment
                  ...LinkAboutFragment
                  ...LinkRetailOSFragment
                }
              }
              column_middle {
                footer_item
                footer_item_link {
                  ...LinkSolutionFragment
                  ...LinkLegalFragment
                  ...LinkResourceFragment
                  ...LinkContactUsFragment
                  ...LinkPlatformFragment
                  ...LinkCompanyFragment
                  ...LinkArticleFragment
                  ...LinkAboutFragment
                  ...LinkRetailOSFragment
                }
              }
              sign_up_body
              sign_up_form_column
              sign_up_title
              sign_up_title_link {
                ...LinkSolutionFragment
                ...LinkLegalFragment
                ...LinkResourceFragment
                ...LinkContactUsFragment
                ...LinkPlatformFragment
                ...LinkCompanyFragment
                ...LinkArticleFragment
                ...LinkAboutFragment
                ...LinkRetailOSFragment
              }
              sign_up_cta
              column_right {
                footer_item
                footer_item_link {
                  ...LinkSolutionFragment
                  ...LinkLegalFragment
                  ...LinkResourceFragment
                  ...LinkContactUsFragment
                  ...LinkPlatformFragment
                  ...LinkCompanyFragment
                  ...LinkArticleFragment
                  ...LinkAboutFragment
                  ...LinkRetailOSFragment
                  ...LinkPharmaosFragment
                }
              }
              title_column_left
              title_column_middle
              title_column_right
              social_media_icons {
                icon
                link {
                  ...LinkExternalFragment
                }
              }
            }
          }
        }
        allLegals {
          edges {
            node {
              _linkType
              page_title
              _meta {
                uid
                type
              }
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={`${footerQuery}`}
      render={withPreview(({ prismic }) => {
        const footerData = prismic.allMain_footers.edges[0].node
        const legal = prismic.allLegals.edges[1].node
        const privacyPolicy = prismic.allLegals.edges[0].node
        const socialMediaIconsList = _get(footerData, 'social_media_icons')
        return (
          <FooterWrapper>
            <Content>
              <MainContainer>
                <LeftContainer>
                  <LeftContainerInnerLeft>
                    <H3 variant={"linkTitle"}>
                      {footerData.title_column_left}
                    </H3>
                    {_get(footerData, "column_left") && (
                      <nav>
                        <ul>
                          {_map(footerData.column_left, (item, key) => (
                            <StyledLink key={key}>
                              <Link to={utils.linkResolver(item.footer_item_link)}>{item.footer_item}</Link>
                            </StyledLink>
                          ))}
                        </ul>
                      </nav>
                    )}
                  </LeftContainerInnerLeft>
                </LeftContainer>
                <MiddleContainer>
                  <H3 variant={"linkTitle"}>
                    {footerData.title_column_middle}
                  </H3>
                  <LimitListStyle>
                    {_get(footerData, "column_right") && (
                      <>
                        {_map(footerData.column_middle, (item, key) => (
                          <StyledLink key={key}>
                            <Link to={utils.linkResolver(item.footer_item_link)}>{item.footer_item}</Link>
                          </StyledLink>
                        ))}
                      </>
                    )}
                  </LimitListStyle>
                </MiddleContainer>
                <RightContainer>
                  <H3 variant={"linkTitle"}>
                    {footerData.title_column_right}
                  </H3>
                  <RightContainerInnerLeft>
                    {_get(footerData, "column_left") && (
                      <nav>
                        <ul>
                          {_map(footerData.column_right, (item, key) => (
                            <StyledLink key={key}>
                              <Link to={utils.linkResolver(item.footer_item_link)}>{item.footer_item}</Link>
                            </StyledLink>
                          ))}
                        </ul>
                      </nav>
                    )}
                  </RightContainerInnerLeft>
                </RightContainer>
                <SignUpContainer>
                  <H3 variant={"linkTitle"}>
                    {footerData.sign_up_form_column}
                  </H3>
                  <Break>
                    <nav>
                      <ul>
                        <StyledLinkTitle>
                          <Link to={utils.linkResolver(footerData.sign_up_title_link)}>{footerData.sign_up_title}</Link>
                        </StyledLinkTitle>
                      </ul>
                    </nav>
                  </Break>
                  <FooterForm FormLabel={footerData.sign_up_body} CTATitle={footerData.sign_up_cta} />
                </SignUpContainer>
              </MainContainer>
            </Content>
            <BottomBar
              privacyPolicy={privacyPolicy}
              legal={legal}
              socialMediaIconsList={socialMediaIconsList} />
          </FooterWrapper>
        )
      }, footerQuery)}
    />
  )
}
//pending final designs
Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer

import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'
import { AppContext } from '../../AppProvider'
import Image from '../Image'
import Cross from './Cross'
import media from '../../styles/media'
import rgba from '../../utils/rgba'

const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 9;
  display: ${({ show }) => show ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => rgba(theme.colors.dark, 0.8)};
  overflow-y: scroll;
`
const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: none;
  margin-bottom: 5rem;

  ${media.greaterThan("tablet")`
    top: 3rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 80%;
  `}
  ${media.greaterThan("desktop")`
    top: 3rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 800px;
  `}
`

const ModalMain = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.125rem;
  background-color: #000;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage.url})`};
  background-blend-mode: screen;
  background-size: cover;
  background-repeat: no-repeat;
  border: 10px solid #fff;
  align-items: center;
  text-align: center;

  > img {
    margin-bottom: 48px;
  }

  > h2 {
    max-width: 648px;
    font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 2.0625rem;
    line-height: 2.5rem;
    color: #ffffff;
    margin-bottom: 43px;
    
  }
  > span {
    color: #FFFFFF;
    font-family: RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.25rem;
    letter-spacing: 0;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom: 8px;
  }

  > p {
    max-width: 606px;
    color: #FFFFFF;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.437rem;
    letter-spacing: 0;
    line-height: 1.875rem;
  }

  ${media.greaterThan("tablet")`
    min-height: 604px;
  `}
`


const PromoModalFlannes = ({
  main,
  open,
  closeModal,
  modalProps
}) => {
  const { openModal } = React.useContext(AppContext)

  const [cookies, setCookie, removeCookie] = useCookies([ 'promo_home_page' ])
  
  const closePromoModal = useCallback(() => {
    closeModal()
    setCookie(`promo_home_page`, true, { path: '/' })
  }, [closeModal, setCookie])

  const {
    popup_flannels_background_image: backgroundImage,
    popup_flannels_logo_image: logoImage,
    popup_flannels_title: title,
    popup_flannels_quote: quote
  } = modalProps
  return (
    <Container
      show={open}
      onClick={closePromoModal}>
      <Wrapper>
        <Cross color={'#ffffff'} onClick={closePromoModal} />
        <ModalMain backgroundImage={backgroundImage}>
          {logoImage && (
            <Image {...logoImage} />
          )}
          <h2>{title}</h2>
          <span>They said:</span>
          <p>{quote}</p>
        </ModalMain>
      </Wrapper>
    </Container>
  )
}

export default PromoModalFlannes
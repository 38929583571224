import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'

import H4 from './../H4'

import utils from '../../utils'


const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2.18rem;
`

const ItemsWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: .5rem;
`

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  min-width: 7.85rem;
  font-family: RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  padding: .4rem 0;
  margin-top: .4rem;
  color: ${props => props.theme.colors.black};
  
  > img {
    width: 5.125rem;
    height: 5.125rem;
  }
`

const QuickAccessMenu = ({ title, items }) => {
  return (
    <Container>
      <H4 variant="primary">{title}</H4>
      <ItemsWrapper>
        {_map(items, item => {
          const quick_access_link = _get(item, 'quick_access_link')
          const page_title = _get(quick_access_link, 'page_title')
          return (
            <Item to={utils.linkResolver(quick_access_link)}>
              {page_title}
            </Item>
          )
        })}
      </ItemsWrapper>
    </Container>)
}

export default QuickAccessMenu
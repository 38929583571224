import React, { useCallback } from 'react'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'
import { AppContext } from '../../AppProvider'
import H2 from '../H2'
import P from '../P'
import Image from '../Image'
import Cross from './Cross'
import media from '../../styles/media'
import utils from '../../utils'
import rgba from '../../utils/rgba'

const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 9;
  display: ${({ show }) => show ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => rgba(theme.colors.dark, 0.8)};
  overflow-y: scroll;
`
const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: none;
  margin-bottom: 5rem;

  ${media.greaterThan("tablet")`
    top: 3rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 80%;
  `}
  ${media.greaterThan("desktop")`
    top: 3rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 800px;
  `}
`

const ModalMain = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 2.125rem;
  background: ${({ backgroundImage, gradientLeft, gradientRight }) => `url(${backgroundImage.url}), linear-gradient(45deg, ${gradientLeft} 0%, ${gradientRight} 100%)`};
  background-blend-mode: screen;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  align-items: center;
  > h2 { color: #000000; }
  ${media.greaterThan("tablet")`
    min-height: 604px;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: .3rem;
  
  ${media.greaterThan('tablet')`
    flex-direction: row;
  `}
`

const ContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.43;
  padding-right: 0;
  > p {
    font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: #1D1D1D;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 29px;
  }
  ${media.greaterThan('tablet')`
    padding-bottom: 0rem;
    padding-right: 1rem;
  `}
`

const ContentAwards = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.57;
  padding-left: 0;
`

const AwardsGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: .3rem;
  ${media.greaterThan('tablet')`
    flex-direction: row;
  `}
`

const AwardsDescription = styled.div`
  width: 100%;
  border-bottom: 3px solid black;
  text-align: center;
  > p { 
    font-size: 15px;
    padding-bottom: 0;
  }  

  ${media.greaterThan('tablet')`
    width: 60%;
    text-align: left;
    border-bottom: none;
    border-right: 3px solid #000000;
  `}
`

const AwardsLogo = styled.div`
  width: 40%;
  max-width: 147px;
	> img {
		width: 100%;
	}
`

const Bottom = styled.div`
  position: relative;
  margin-bottom: 1.3rem;
`
const StartButton = styled.button`
  z-index: 9;
  background-color: transparent;
  outline: none;
  border: none;
  display: block;
  margin: 0 auto;
  width: 198px;
  cursor: pointer;
  > img {
    width: 100%;
  }
  
  ${media.greaterThan('tablet')`
    position: absolute;
    top: -18px;
    left: 95px;
    &:hover {
      > img {
        filter: drop-shadow(4px 12px 10px rgba(0,0,0,0.5));
      }
    }
  `}
`

const SupportImage = styled.div`
  display: none;
  max-width: 685px;
  > img {
    width: 100%;
  }
  ${media.greaterThan('tablet')`
    display: block;
  `}
`

const PromoModal = ({
  main,
  open,
  closeModal,
  modalProps
}) => {
  const { openModal } = React.useContext(AppContext)

  const [cookies, setCookie, removeCookie] = useCookies([ 'promo_home_page' ])
  
  const closePromoModal = useCallback(() => {
    closeModal()
    setCookie(`promo_home_page`, true, { path: '/' })
  }, [closeModal, setCookie])

  const {
    popup_title: title,
    popup_description: description,
    popup_awards_title: awardsTitle,
    popup_awards_description: awardsDescription,
    popup_awards_logo: awardsLogoImage,
    popup_background_image: backgroundImage,
    popup_background_gradient_left: gradientLeft,
    popup_background_gradient_right: gradientRight,
    popup_video_button_image: buttonImage,
    popup_supporting_image: supportingImage,
    popup_vimeo_video_id: videoId,
    popup_vimeo_modal_title: videoTitle
  } = modalProps

  return (
    <Container
      show={open}
      onClick={closePromoModal}>
      <Wrapper>
        <Cross color={'#000000'} onClick={closePromoModal} />
        <ModalMain
          backgroundImage={backgroundImage}
          gradientLeft={gradientLeft}
          gradientRight={gradientRight}>
            <H2 variant='heading'>{title}</H2>
            <Content>
              <ContentDescription>
                <RichText render={description} htmlSerializer={utils.htmlSerializer} />
              </ContentDescription>
              <ContentAwards>
                <P variant={'smallHeading'}>{awardsTitle}</P>
                <AwardsGrid>
                  <AwardsDescription>
                    <RichText render={awardsDescription} htmlSerializer={utils.htmlSerializer} />
                  </AwardsDescription>
                  <AwardsLogo>
                    <Image {...awardsLogoImage} />
                  </AwardsLogo>
                </AwardsGrid>
              </ContentAwards>
            </Content>
            <Bottom>
              <StartButton
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  openModal({
                    type: 'iframe',
                    main: <iframe src={`https://player.vimeo.com/video/${videoId}`} width="604" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  })
                }
                }
              >
                <Image {...buttonImage} />
              </StartButton>
              <SupportImage>
                <Image {...supportingImage} />
              </SupportImage>
            </Bottom>
        </ModalMain>
      </Wrapper>
    </Container>
  )
}

export default PromoModal
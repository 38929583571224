import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import _get from 'lodash/get'
import _size from 'lodash/size'
import _map from 'lodash/map'

import { AppContext } from '../../AppProvider'

import media from '../../styles/media'
import vars from '../../styles/vars'
import utils from '../../utils'

const Container = styled.div`
  width: ${({ isActive }) => isActive ? `80%` : 0};
  max-width: 80%;
  height: 100%;
  display: block;
  z-index: 9;
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width .25s;
  background: ${({ theme }) => theme.colors.darkBackground};
  
  ${media.greaterThan("tablet")`
    width: ${({ isActive }) => isActive ? `${vars.mobileSideMenuWidth.lg}rem` : 0};
  `}
  /* hide mobile menu on desktop */
  ${media.greaterThan("desktop")`
    width: 0;
  `}
`

const Wrapper = styled.div`
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  padding-bottom: 1.2rem;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
  > ul { margin: 0; }
`

const AccessLink = styled(Link)`
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.75rem;
  line-height: 1.07;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  padding: .3rem 0;
  margin-bottom: .2rem;

  &:hover {
    color: ${({ theme }) => utils.rgba(theme.colors.white, 0.8)};
  }
`

const HeadLink = styled(Link)`
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.0625rem;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 1rem;

  &:hover {
    color: ${({ theme }) => utils.rgba(theme.colors.white, 0.8)};
  }
`

const SubHeader = styled.div`
  font-family: RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 1.4rem;
`

const SimpleLink = styled.li`
  font-family: RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  padding-bottom: 1rem;
  margin-bottom: .5rem;
  > a { color: ${({ theme }) => theme.colors.grey}; }
  > a:hover { 
    color: ${({ theme }) => utils.rgba(theme.colors.white, 1)};
  }
`

const LinkItem = ({ name, href }) => <SimpleLink><Link to={href}>{name}</Link></SimpleLink>

const MobileMenu = ({ items, buttonOne, buttonTwo }) => {
  const { mobileMenu, toggleMenu } = React.useContext(AppContext)
  return (
    <Container isActive={mobileMenu.open}>
      <Wrapper>
        {(buttonOne.link || buttonTwo.link) && (
          <Nav>
            {buttonOne.title && <AccessLink to={buttonOne.link} onClick={toggleMenu} color={buttonOne.color}>{buttonOne.title}</AccessLink>}
            {buttonTwo.title && <AccessLink to={buttonTwo.link} onClick={toggleMenu} color={buttonTwo.color}>{buttonTwo.title}</AccessLink>}
          </Nav>
        )}
        {_map(items, ({ menu_item }, index) => {
          const {
            name,
            has_dropdown_menu,
            link,
            main_navigation_title,
            main_navigation,
            bottom_navigation_title,
            bottom_navigation,
            quick_access_title,
            quick_access_navigation
          } = menu_item || {}

          const hasMainNav = _size(main_navigation) > 0
          const hasBottomNav = _size(bottom_navigation) > 0
          const hasQuickAccessNav = _size(quick_access_navigation) > 0

          return (
            <Nav key={index}>
              <HeadLink to={utils.linkResolver(link)} onClick={toggleMenu}>{name}</HeadLink>
              {has_dropdown_menu && (
                <>
                  {/* Main Nav */}
                  {hasMainNav && (
                    <>
                      <SubHeader>{main_navigation_title}</SubHeader>
                      <ul onClick={toggleMenu}>
                        {_map(main_navigation, ({ main_nav_link_name, main_nav_link }, index) => {
                          const href = utils.linkResolver(main_nav_link)
                          return <LinkItem key={main_nav_link_name} name={main_nav_link_name} href={href} />
                        })}
                      </ul>
                    </>
                  )}
                  {/* Bottom Nav */}
                  {hasBottomNav && (
                    <>
                      <SubHeader>{bottom_navigation_title}</SubHeader>
                      <ul onClick={toggleMenu}>
                        {_map(bottom_navigation, ({ bottom_nav_link_name, bottom_nav_link }) => {
                          const href = utils.linkResolver(bottom_nav_link)
                          return <LinkItem key={bottom_nav_link_name} name={bottom_nav_link_name} href={href} />
                        })}
                      </ul>
                    </>
                  )}
                  {/* Quick Access Nav */}
                  {hasQuickAccessNav > 0 && (
                    <>
                      <SubHeader>{quick_access_title}</SubHeader>
                      <ul onClick={toggleMenu}>
                        {_map(quick_access_navigation, ({ quick_access_link }) => {
                          const name = _get(quick_access_link, 'page_title', '')
                          return <LinkItem key={name} name={name} href={utils.linkResolver(quick_access_link)} />
                        })}
                      </ul>
                    </>
                  )}
                </>
              )}
            </Nav>
          )
        })}
      </Wrapper>
    </Container>
  )
}

export default MobileMenu